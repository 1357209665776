<script setup>
const model = defineModel();
const props = defineProps({
  onlyIntegrated: {
    type: Boolean,
    default: false,
  },
});

const { exchanges, integrated } = useExchanges();

const mappedExchanges = computed(() => {
  if (props.onlyIntegrated) {
    return integrated.value.map((ex) => {
      return {
        id: ex.key,
        label: ex.name,
        avatar: ex.avatar,
      };
    });
  }
  return exchanges.map((ex) => {
    return {
      id: ex.key,
      label: ex.name,
      avatar: ex.avatar,
    };
  });
});

const selected = ref(mappedExchanges.value.find((ex) => ex.id === model.value));

watch(selected, (value) => {
  model.value = value.id;
});
</script>

<template>
  <USelectMenu v-model="selected" :options="mappedExchanges">
    <template #label>
      <UAvatar :src="`/images/${selected.avatar}`" alt="Avatar" size="xs" />
      {{ selected.label }}
    </template>

    <template #option="{ option: exchange }">
      <UAvatar :src="`/images/${exchange.avatar}`" alt="Avatar" size="xs" />
      {{ exchange.label }}
    </template>
  </USelectMenu>
</template>
