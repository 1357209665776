<script setup>
const props = defineProps({
  majorCoin: {
    type: Object,
    required: true,
  },
  minorCoin: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["update:majorCoin", "update:minorCoin"]);

const majorCoin = ref(props.majorCoin);
const minorCoin = ref(props.minorCoin);

const switchCoins = () => {
  const temp = majorCoin.value;
  majorCoin.value = minorCoin.value;
  minorCoin.value = temp;
};

watch(majorCoin, (value) => {
  emit("update:majorCoin", value);
});

watch(minorCoin, (value) => {
  emit("update:minorCoin", value);
});
</script>

<template>
  <div class="flex">
    <CoinDropdown v-model="majorCoin" :ui="{ rounded: 'rounded-r-none' }" />
    <UButton
      icon="i-heroicons-arrows-right-left-solid"
      size="sm"
      color="gray"
      square
      variant="solid"
      :ui="{ rounded: 'rounded-none' }"
      @click="switchCoins" />
    <CoinDropdown v-model="minorCoin" :ui="{ rounded: 'rounded-l-none' }" />
  </div>
</template>

<style lang="scss" scoped></style>
